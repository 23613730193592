import { useState, useEffect, useContext } from "react";
import {
  Collapse,
  Input,
  Typography,
  Row,
  Col,
  Form,
  Button,
  notification,
  Steps,
  Progress,
  Tooltip,
} from "antd";
import _ from "lodash";
import {
  CheckCircleOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import "./TaskSteps.css";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import {
  handleError,
  validateMedicareNumber,
  formatMessage,
} from "../utils/utilities";
import TaskResubmitForm from "./TaskResubmitForm";
import { useTheme } from "../context/ThemeContext";
import ConfigContext from "../context/ConfigContext";
import TaskIssues from "./TaskIssues";
import DocumentTable from "./DocumentTable";

const { Panel } = Collapse;
const TaskSteps = (props) => {
  const { isDarkMode } = useTheme();
  const [form] = Form.useForm();
  const [validationForm] = Form.useForm();
  const [hasData, setHasData] = useState(false);
  const stepsStatus = props.stepsStatus;
  const taskId = props.taskId;
  const metadata = props.metadata;
  const fileData = props.fileData;
  const structuredData = props.structuredData;
  const [initialData, setInitialData] = useState(null);
  const [extractedFieldsWithMd, setExtractedFieldsWithMd] = useState(null);
  const [inKey, setInKey] = useState(null); // Key of the nested dict we found demographics info in.
  const [inValue, setInValue] = useState({});
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [validMedicareNumber, setValidMedicareNumber] = useState(true);
  const [percentageCompleted, setPercentageCompleted] = useState(0);
  const [resumed, setResumed] = useState(false);
  const [showMissingValues, setShowMissingValues] = useState(false);
  const refreshResubmittedTask = props.refreshResubmittedTask;
  const issues = props.metadata.data_incomplete_reasons;
  const config = useContext(ConfigContext);
  const readOnly = ["Processing", "Pending"].includes(stepsStatus.status);
  const DEFAULT_TOOLTIP = "No additional information available.";

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notification.success({
          message: formatMessage(config.messages.copy.copy),
        });
      })
      .catch(() => {
        notification.error({
          message: formatMessage(config.messages.copy.failed),
        });
      });
  };

  const getPrioritizedData = (structuredData) => {
    const filteredItems = structuredData.filter(
      (d) => d.name === "extracted-fields"
    );
    // Find the first item with 'user_edit' source if it exists, otherwise take the first item
    return (
      filteredItems.find((d) => d.source === "user_edit") || filteredItems[0]
    );
  };

  const getUnPrioritizedData = (structuredData) => {
    const filteredItems = structuredData.filter(
      (d) => d.name === "extracted-fields"
    );
    const filteredItemsCopy = JSON.parse(JSON.stringify(filteredItems));
    if (filteredItemsCopy.length > 0) {
      delete filteredItemsCopy[0].data[""];
      setExtractedFieldsWithMd(filteredItemsCopy[0].data);
    }
  };

  const lookupMetadata = (metadata, keyArray, value) => {
    if (!extractedFieldsWithMd || !Array.isArray(keyArray)) {
      console.error("Invalid extractedFieldsWithMd or keyArray");
      return {
        fileNames: "",
        pages: [],
        otherValues: [],
        result: DEFAULT_TOOLTIP,
      };
    }

    // Initialize Sets to avoid duplicates
    const fileNamesSet = new Set();
    const pagesSet = new Set();
    const otherValuesSet = new Set();

    // Iterate over each document in extractedFieldsWithMd
    Object.entries(extractedFieldsWithMd).forEach(([docKey, item]) => {
      // Traverse the nested object using keyArray to get the target value
      let targetValue = keyArray.reduce((acc, key) => acc && acc[key], item);

      let dNested =
        keyArray.length > 0 &&
        ["e_signature", "procedure_codes"].includes(keyArray[0]);

      if (targetValue === undefined) {
        try {
          // Example for a specific deep nesting pattern
          item[keyArray[0]] = Object.values(item[keyArray[0]]).flat();
          targetValue = keyArray.reduce((acc, key) => acc && acc[key], item);
          dNested = true;
        } catch (e) {
          console.log("Error accessing deeply nested metadata: ", e, keyArray);
        }
      }
      if (targetValue === value) {
        fileNamesSet.add(docKey);

        // Get raw metadata for the target key
        const rawMetadataKey = `${keyArray[keyArray.length - 1]}_raw_metadata`;
        const rawMetadata = keyArray
          .slice(0, -1)
          .reduce((acc, key) => acc && acc[key], item);

        // Adjust for deeply nested metadata in case of fields like 'address'
        let finalRawMetadata =
          rawMetadata && rawMetadata[rawMetadataKey]
            ? rawMetadata[rawMetadataKey]
            : item[`${keyArray[0]}`][`${keyArray[1]}_raw_metadata`] || {};

        if (dNested) {
          finalRawMetadata =
            item?.[keyArray[0]]?.[keyArray[1]]?.["_raw_metadata"];
        }
        if (finalRawMetadata) {
          const { pages, all_values } = finalRawMetadata;

          // Increment page numbers by 1 and add to pagesSet
          if (pages) {
            pages.forEach((page) => pagesSet.add(parseInt(page) + 1));
          }

          // Add other values excluding the current value
          if (all_values) {
            all_values.forEach((val) => {
              // check if the value is an object and extract the value
              if (typeof val === "object" && keyArray.length > 2) {
                val = val[keyArray[keyArray.length - 1]];
              }
              if (val !== value && val !== null && val !== "") {
                otherValuesSet.add(val);
              }
            });
          }
        }
      }
    });

    // Convert Sets to arrays and sort them
    const fileNames = Array.from(fileNamesSet).sort();
    const pages = Array.from(pagesSet).sort((a, b) => a - b);
    const otherValues = Array.from(otherValuesSet);

    // Construct result string
    let result = "No additional information available.";
    if (fileNames.length > 0 || pages.length > 0 || otherValues.length > 0) {
      result = "";
      if (fileNames.length > 0) {
        result += `Found in file(s): ${fileNames.join(", ")}. `;
      }
      if (pages.length > 0) {
        result += `On page(s): ${pages.join(", ")}. `;
      }
      if (otherValues.length > 0) {
        result += `Other values: ${otherValues.join(", ")}.`;
      }
      result = result.trim();
    }

    return {
      fileNames: fileNames.join(", "),
      pages,
      otherValues,
      result,
    };
  };

  const extractInitialValues = (data) => {
    if (data.demographics || data.insurance) return data;

    const firstKey = Object.keys(data)[0];
    return data[firstKey];
  };

  const replaceUnderScoreWithSpace = (str) => {
    return str.replace(/_/g, " ");
  };

  const capitalizeString = (str) => {
    return replaceUnderScoreWithSpace(str).toUpperCase();
  };

  const NON_INSURANCE_TASK_TYPE = "soap_avs";

  // First useEffect to set initialData and trackingInfo
  useEffect(() => {
    const newData = getPrioritizedData(structuredData);

    if (newData && newData.data && !newData.data.demographics) {
      setInKey(Object.keys(newData.data)[0]);
    }
    setInitialData(newData);
    getUnPrioritizedData(structuredData);

    const extractedValues = newData ? extractInitialValues(newData.data) : {};
    setInValue(extractedValues);

    let demographicInfo =
      extractedValues && extractedValues.demographics
        ? {
            first_name: extractedValues.demographics.first_name,
            last_name: extractedValues.demographics.last_name,
            DOB: extractedValues.demographics.DOB,
          }
        : !!newData
          ? {}
          : null; // for legacy tasks w/o extracted fields don't show patient record.

    if (stepsStatus.task_type !== NON_INSURANCE_TASK_TYPE) {
      const isMedicare = extractedValues.insurance?.insurance_carrier
        ?.toLowerCase()
        .includes("medicare");
      demographicInfo = {
        ...demographicInfo,
        insurance_carrier: extractedValues.insurance?.insurance_carrier,
      };

      if (isMedicare) {
        try {
          const isValidMedicareId = validateMedicareNumber(
            extractedValues.insurance?.insurance_id
          );
          setValidMedicareNumber(isValidMedicareId);
          if (!isValidMedicareId) {
            demographicInfo = {
              ...demographicInfo,
              insurance_id: extractedValues.insurance?.insurance_id,
            };
          }
        } catch (e) {
          console.log("Error validating medicare id: ", e);
        }
      }
    }
    setTrackingInfo(demographicInfo);
    setPercentageCompleted(stepsStatus.percentage_completed);
  }, [
    metadata,
    structuredData,
    stepsStatus.task_type,
    setValidMedicareNumber,
    stepsStatus.percentage_completed,
  ]);

  useEffect(() => {
    if (form && inValue) {
      form.setFieldsValue(inValue);
    }
  }, [inValue, form]);

  useEffect(() => {
    if (validationForm && trackingInfo) {
      validationForm.setFieldsValue(trackingInfo);
    }
  }, [trackingInfo, validationForm]);

  const handleSubmit = () => {
    const values = form.getFieldsValue(true);
    console.log(values);
    let pushData = {};
    if (inKey !== null) {
      pushData[inKey] = values;
    } else {
      pushData = values;
    }

    let formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        items: [
          {
            output: JSON.stringify(pushData),
            data_id: initialData.id,
          },
        ],
      })
    );

    AuthenticatedApi.post(`/api/v2/tasks/${taskId}/push_info`, formData)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
        } else {
          console.log(response.data.message);
        }
        notification.success({
          message: formatMessage(config.messages.tasks.fields_updated),
        });
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      });
  };

  const handleReSubmit = (values) => {
    const newData = initialData;
    console.log("In Key:", inKey);
    if (inKey == null) {
      newData.data.demographics = {
        ...newData.data.demographics,
        first_name: values.first_name,
        last_name: values.last_name,
        DOB: values.DOB,
      };

      if (stepsStatus.task_type !== NON_INSURANCE_TASK_TYPE) {
        newData.data.insurance = {
          ...newData.data.insurance,
          insurance_carrier: values.insurance_carrier,
          //insurance_plan: values.insurance_plan,
          //order_type: values.order_type
        };
      }
    } else {
      newData.data[inKey].demographics = {
        ...newData.data[inKey].demographics,
        first_name: values.first_name,
        last_name: values.last_name,
        DOB: values.DOB,
      };

      if (stepsStatus.task_type !== NON_INSURANCE_TASK_TYPE) {
        newData.data[inKey].insurance = {
          ...newData.data[inKey].insurance,
          insurance_carrier: values.insurance_carrier,
          //insurance_plan: values.insurance_plan,
          //order_type: values.order_type
        };
      }

      if (!validMedicareNumber) {
        const isValidMedicareId = validateMedicareNumber(values.insurance_id);
        if (!isValidMedicareId) {
          notification.error({
            message: formatMessage(config.messages.tasks.medicare_validation),
          });
          return;
        } else {
          newData.data[inKey].insurance = {
            ...newData.data[inKey].insurance,
            insurance_id: values.insurance_id,
          };
        }
      }
    }
    return {
      items: [
        {
          output: JSON.stringify(newData.data),
          data_id: initialData.id,
        },
      ],
      error: null,
    };
  };

  const resumeFailedTask = () => {
    setResumed(true);
    AuthenticatedApi.get(`/api/tasks/${taskId}/resume-failed-task`)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          if (refreshResubmittedTask) {
            refreshResubmittedTask(taskId, true);
            setResumed(false);
          }
          notification.success({
            message: formatMessage(config.messages.tasks.resumed),
          });
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      });
  };

  const getHeader = (title, step) => {
    const { current_phase, status } = stepsStatus;
    let icon;

    if (stepsStatus.status === "Completed") {
      icon = <CheckCircleOutlined style={{ color: "#52c41a" }} />;
    } else if (current_phase === step) {
      icon =
        status === "Failed" || status === "Invalid" ? (
          <CloseCircleOutlined style={{ color: "red" }} />
        ) : (
          <LoadingOutlined />
        );
    } else if (current_phase > step) {
      icon = <CheckCircleOutlined style={{ color: "#52c41a" }} />;
    } else {
      icon = <ExclamationCircleOutlined />;
    }

    return (
      <span
        style={
          current_phase >= step
            ? { fontWeight: "bolder", fontSize: "14px" }
            : {}
        }
      >
        {icon} {title}
      </span>
    );
  };

  const extractLeafKeys = (obj, parent = []) => {
    if (typeof obj !== "object" || obj === null) return [];
    return Object.entries(obj).flatMap(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        return [
          { path: parent.concat(key), value: "", hasChild: true },
          ...extractLeafKeys(value, parent.concat(key)),
        ];
      } else {
        return { path: parent.concat(key), value: value, hasChild: false };
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  const RenderJSONFields = ({ data, form, readOnly }) => {
    const [leafKeys, setLeafKeys] = useState(extractLeafKeys(data));
    useEffect(() => {
      const hasInputData = leafKeys.some((item) => item.value);
      setHasData(hasInputData);
    }, [leafKeys]);

    const hasNonEmptyFieldsInSection = (section) => {
      return leafKeys.some(
        (item) => item.path[0] === section && (showMissingValues || item.value)
      );
    };
    // TODO : improve how we calculate confidence and not mask fields by names that could overlap
    const isConfidenceField = (item) => {
      return item.path[1] === `${item.path[0].toLowerCase()}_confidence`;
    };

    const handleDelete = (path) => {
      const newData = { ...data };
      let current = newData;
      for (let i = 0; i < path.length - 1; i++) {
        current = current[path[i]];
      }
      current[path[path.length - 1]] = null;
      setLeafKeys(extractLeafKeys(newData));
      // Update form values correctly for nested fields
      const updatedValues = form.getFieldsValue(true);
      let formCurrent = updatedValues;
      for (let i = 0; i < path.length - 1; i++) {
        formCurrent = formCurrent[path[i]];
      }
      formCurrent[path[path.length - 1]] = null;
      form.setFieldsValue(updatedValues);
      notification.success({
        message: `${path[path.length - 1]} marked for deletion, please submit to save changes.`,
      });
    };

    const getTooltipContent = (item) => {
      const metadata = lookupMetadata(data, item.path, item.value); // Customize this based on your lookup function
      return metadata.result;
    };

    return (
      <>
        {leafKeys.map((item, index) => {
          if (
            item.path.length === 1 &&
            hasNonEmptyFieldsInSection(item.path[0])
          ) {
            return (
              <div key={index}>
                <Typography.Title level={5}>
                  {capitalizeString(item.path[0])}
                </Typography.Title>
              </div>
            );
          }
          if (
            !item.hasChild &&
            (showMissingValues ||
              (item.value && typeof item.value !== Object)) &&
            !isConfidenceField(item) &&
            !item.path.some((p) => p.includes("_raw_metadata"))
          ) {
            return (
              <div key={index} style={{ marginBottom: "10px" }}>
                <Row gutter={8} align="middle">
                  <Col xs={24} sm={4} style={{ textAlign: "left" }}>
                    <span>
                      {replaceUnderScoreWithSpace(
                        `${item.path[1]}${item.path[2] ? ` ${item.path[2]}` : ""}`
                      )}
                      :
                    </span>
                  </Col>
                  <Col xs={20} sm={14}>
                    <Form.Item name={item.path} noStyle>
                      <Input readOnly={readOnly} />
                    </Form.Item>
                  </Col>
                  <Col xs={6} sm={6}>
                    {(() => {
                      const tooltipContent = getTooltipContent(item);
                      return tooltipContent !== DEFAULT_TOOLTIP ? (
                        <Tooltip title={tooltipContent}>
                          <Button icon={<InfoCircleOutlined />} />
                        </Tooltip>
                      ) : (
                        <Button disabled icon={<InfoCircleOutlined />} />
                      );
                    })()}
                    <Button
                      icon={<CopyOutlined />}
                      onClick={() => copyToClipboard(item.value)}
                    />
                    <Button
                      title="Delete this field"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDelete(item.path)}
                    />
                  </Col>
                </Row>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  const notEmpty = (maybeList) => {
    return !!maybeList && maybeList.length < 0;
  };

  const generateStepItems = (percentage) => {
    // Not sure if these are the right titles
    // Kept the steps as these are giving some idea of what is happening
    // With just the progress bar, it is hard to tell what is happening
    // TDOD: Need to get the right titles
    const titles = [
      "File Uploaded",
      "File Processed",
      "AI Reasoning",
      "Output Generated",
    ];
    const totalSteps = titles.length;
    const currentStep = Math.ceil((percentage / 100) * totalSteps);
    const invalidOrFailed =
      stepsStatus.status === "Invalid" || stepsStatus.status === "Failed";

    return titles.map((title, index) => {
      let status = "wait";
      let iconStyle = { color: "#939393" };
      let IconComponent = ExclamationCircleOutlined;

      if (index < currentStep) {
        status = "finish";
        iconStyle.color = "#52c41a";
        IconComponent = CheckCircleOutlined;
      } else if (index === currentStep && !invalidOrFailed) {
        status = "process";
        IconComponent = LoadingOutlined;
      }

      return {
        title: title,
        status: status,
        icon: <IconComponent style={iconStyle} />,
      };
    });
  };

  const generatedLetterBackgroundStyle = {
    backgroundColor: isDarkMode ? "#1f1f1f" : "#e3e3e3",
    marginTop: "1%",
  };

  return (
    <div>
      {stepsStatus.status !== "Blocked" ? (
        <div style={{ width: "100%", marginTop: "1%" }}>
          <Steps items={generateStepItems(percentageCompleted)} />
          <Progress percent={percentageCompleted} status="active" />
        </div>
      ) : (
        <div style={{ width: "100%", marginTop: "1%" }}>
          <div
            className="generated-letter"
            style={generatedLetterBackgroundStyle}
          >
            <Typography.Title level={4}>Analysis Checklist</Typography.Title>
            {stepsStatus && notEmpty(issues) && (
              <Collapse
                collapsible="disabled"
                expandIcon={() => null}
                style={{ width: "100%", marginTop: "2%" }}
              >
                <Panel
                  header={getHeader("Require Following Information", 4)}
                  key="4"
                ></Panel>
              </Collapse>
            )}
            {stepsStatus.status === "Blocked" && (
              <>
                {metadata.error_message && (
                  <span
                    style={{
                      marginTop: "1%",
                      padding: "1%",
                      borderRadius: "4px",
                    }}
                  >
                    {metadata.error_message}
                  </span>
                )}
                <TaskResubmitForm
                  issues={_.uniq(issues)}
                  taskId={taskId}
                  refreshResubmittedTask={refreshResubmittedTask}
                  patientInfoDetails={{
                    trackingInfo,
                    stepsStatus,
                    metadata,
                    fileData,
                    validationForm,
                    handleReSubmit,
                    non_insurance_task_type: NON_INSURANCE_TASK_TYPE,
                  }}
                />
              </>
            )}
          </div>
        </div>
      )}
      {stepsStatus && stepsStatus.status === "Failed" && (
        <div
          className="generated-letter"
          style={generatedLetterBackgroundStyle}
        >
          <Typography.Title level={3}>Taskflow Failure</Typography.Title>
          <pre
            style={{
              marginTop: "1%",
              border: "1px solid #e8e8e8",
              padding: "1%",
              borderRadius: "4px",
            }}
          >
            {metadata.error_message}
          </pre>
          <Button
            type="primary"
            onClick={resumeFailedTask}
            title="Restart the Failed Task."
            disabled={resumed}
          >
            Resume Failed Task
          </Button>
          {metadata.identified_documents &&
            metadata.identified_documents.length > 0 && (
              <div>
                <h4 style={{ marginTop: "20px" }}>Documents</h4>
                <DocumentTable
                  dataSource={metadata.identified_documents}
                  fileData={fileData}
                />
              </div>
            )}
        </div>
      )}
      {stepsStatus && stepsStatus.status === "Invalid" && (
        <div
          className="generated-letter"
          style={generatedLetterBackgroundStyle}
        >
          <Typography.Title level={3}>Invalid Document</Typography.Title>
          <pre
            style={{
              marginTop: "1%",
              border: "1px solid #e8e8e8",
              padding: "1%",
              borderRadius: "4px",
            }}
          >
            Uploaded document does not contains any medical information.
          </pre>
        </div>
      )}
      {stepsStatus && stepsStatus.status !== "Blocked" && (
        <>
          {_.uniq(issues).length > 0 && (
            <h4 style={{ marginTop: "0px" }}>Issues</h4>
          )}
          {/* Display all the issues */}
          <div style={{ marginBottom: "10px" }}>
            <TaskIssues taskId={taskId} issues={_.uniq(issues)} />
          </div>
        </>
      )}
      {stepsStatus && stepsStatus.status !== "Processing" && (
        <div
          className="generated-letter"
          style={generatedLetterBackgroundStyle}
        >
          <div style={{ position: "relative", width: "100%" }}>
            <Button
              type="link"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                color: "blue",
                textDecoration: "underline",
              }}
              onClick={() => setShowMissingValues(!showMissingValues)}
            >
              {showMissingValues ? "Hide blank fields" : "Show blank fields"}
            </Button>
          </div>
          <Typography.Title level={4}>
            Editable Extracted Fields
          </Typography.Title>
          <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={inValue}
            style={{ marginTop: "5%" }}
          >
            {initialData && (
              <RenderJSONFields
                key={initialData.id}
                data={inValue}
                form={form}
                setHasData={setHasData}
                readOnly={readOnly}
              />
            )}
            {/* Only displaying the prioritized extracted-field data */}
            {/*structuredData && structuredData.map((data, index) => {
                          if (data.name === 'extracted-fields') {
                              return <RenderJSONFields key={data.id} data={inValue} form={form} setHasData={setHasData} />;
                          }
                          return null;
                      })*/}
            {hasData && (
              <Button
                type="primary"
                htmlType="submit"
                title="Update Fields Information"
                disabled={readOnly}
              >
                Submit
              </Button>
            )}
          </Form>
        </div>
      )}
    </div>
  );
};

export default TaskSteps;
