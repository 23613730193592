import axios from "axios";
import { useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Input, Modal, notification } from "antd";
import { Navigate, useLocation } from "react-router-dom";
import ConfigContext from "../context/ConfigContext";
import { useTheme } from "../context/ThemeContext";

import "../style.css";
import logo from "../assets/logo.png";
import logolight from "../assets/logolight.png";

const LoginPage = () => {
  const { isDarkMode } = useTheme();
  const [passwordResetVisible, setPasswordResetVisible] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const appConfig = useContext(ConfigContext);
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();

  const redirectUri = location.state?.from?.pathname || "/tasks-list";

  // redirect to /clinical-eligibility if already logged in
  if (!isLoading && isAuthenticated) {
    return <Navigate to={redirectUri} replace />;
  }

  const validEmail = (email) => {
    // Basic email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.trim());
  };

  const endPasswordReset = () => {
    setPasswordResetVisible(false);
    setResetEmail("");
  };

  const handlePasswordReset = async () => {
    var params = {
      method: "POST",
      url: `https://${appConfig.auth0_domain}/dbconnections/change_password`,
      headers: { "content-type": "application/json" },
      data: {
        client_id: appConfig.auth_client_id,
        email: resetEmail.trim(),
        connection: "Username-Password-Authentication",
      },
    };

    try {
      var result = await axios.request(params);
      notification.success({
        message: "Success",
        description: "A password reset email has been sent.",
      });
      console.log(result.data);
    } catch (error) {
      notification.error("Cannot send password reset email:\n" + error);
      console.log(error);
    } finally {
      endPasswordReset();
    }
  };

  return (
    <>
      <div className="logoContainer">
        <img
          src={isDarkMode ? logo : logolight}
          alt="Synthpop Logo"
          className="logoStyle"
        />
      </div>
      <div className="centeredDiv">
        <Button
          style={{ margin: 30 }}
          onClick={() =>
            loginWithRedirect({
              appState: { returnTo: redirectUri },
            })
          }
          type="primary"
        >
          Log In
        </Button>
        <a href="#" onClick={() => setPasswordResetVisible(true)}>
          Forgot Password?
        </a>
        <Modal
          title="Password Reset"
          open={passwordResetVisible}
          onOk={() => handlePasswordReset()}
          okButtonProps={{ disabled: !validEmail(resetEmail) }} // Disable "OK" if no email.
          onCancel={() => endPasswordReset()}
          style={{ maxWidth: "500px" }}
        >
          <p>
            If you signed up using Username and Password Authentication, you can
            request an email to reset your password to be sent to you:
          </p>
          <Input
            placeholder="Enter your email address."
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
          />
        </Modal>
      </div>
    </>
  );
};

export default LoginPage;
