import { useState } from "react";
import { Modal, Button } from "antd";

const ConfirmationButton = ({
  onConfirm,
  title,
  description,
  children,
  ...props
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showConfirmDialog = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    onConfirm(); // Call the actual event handler
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* Button that triggers the confirmation modal */}
      <Button {...props} onClick={showConfirmDialog}>
        {children}
      </Button>

      {/* Inline Modal */}
      <Modal
        title={
          <div style={{ fontSize: "20px", fontWeight: "bold" }}>
            {title || "Are you sure?"}
          </div>
        }
        visible={isModalVisible}
        onOk={handleOk} // Confirm action
        onCancel={handleCancel} // Cancel action
        okText="Yes"
        cancelText="No"
      >
        <p>{description || "Do you want to proceed with this action?"}</p>
      </Modal>
    </>
  );
};

export default ConfirmationButton;
