import { useState, useEffect, useContext } from "react";
import { Table, Button, Modal, notification, Space, Spin, Input } from "antd";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import CopyIconToClipboard from "./CopyIconToClipboard";
import ConfirmationButton from "./ConfirmationButton";
import ApiSecretDialog from "./ApiSecretDialog";
import ConfigContext from "../context/ConfigContext";
import { formatMessage } from "../utils/utilities";

const ApiKeysTable = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [apiDomains, setApiDomains] = useState([]);
  const [createSecretRowId, setCreateSecretRowId] = useState(null);
  const [newApiDomain, setNewApiDomain] = useState("");
  const [createApiVisible, setCreateApiVisible] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [apiSecretShown, setApiSecretShown] = useState(null);
  const config = useContext(ConfigContext);

  const fetchApiKeys = async () => {
    try {
      const response = await AuthenticatedApi.get("/api/organization/api-keys");
      setApiKeys(response.data.api_keys);
    } catch (error) {
      console.error("Error fetching API keys:", error);
    }
  };

  const fetchApiDomains = async () => {
    try {
      const response = await AuthenticatedApi.get(
        "/api/organization/api_domain/get_all"
      );
      setApiDomains(response.data.api_domains);
    } catch (error) {
      console.error("Error reading API domains: ", error);
    }
  };

  useEffect(() => {
    fetchApiKeys();
    fetchApiDomains();
  }, []);

  const handleShowToken = async (apiKeyId) => {
    try {
      const response = await AuthenticatedApi.get(
        `/api/organization/api-keys/${apiKeyId}`
      );
      setSelectedToken(response.data);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error fetching API key:", error);
    }
  };

  const handleCreateApiSecret = async (name) => {
    setCreateSecretRowId(name);
    try {
      const response = await AuthenticatedApi.post(
        "/api/organization/create-api-secret",
        {},
        { params: { api_domain: name } }
      );
      setApiSecretShown(response.data);
    } catch (error) {
      console.error("Error creating API key:", error);
      notification.error({
        message: formatMessage(config.messages.api_keys.key_created_failure),
      });
    } finally {
      setCreateSecretRowId(null);
    }
  };

  const handleDeleteApiDomain = async (name) => {
    setCreateSecretRowId(name);
    try {
      await AuthenticatedApi.delete("/api/organization/api_domain/delete", {
        params: { api_domain: name },
      });
    } catch (error) {
      console.error("Error deleting API Domain:", error);
      notification.error({
        message: formatMessage(config.messages.api_keys.domain_deleted_failure),
      });
    } finally {
      fetchApiDomains();
      setCreateSecretRowId(null);
    }
  };

  const cleanupCreateApiDomain = async () => {
    setCreateApiVisible(false);
    setNewApiDomain("");
  };

  const handleCreateApiDomain = async () => {
    var response = null;

    try {
      response = await AuthenticatedApi.post(
        "/api/organization/api_domain/create",
        {},
        { params: { api_domain: newApiDomain.trim() } }
      );
    } catch (error) {
      console.error("Error deleting API Domain:", error);
      notification.error({
        message: formatMessage(config.messages.api_keys.domain_created_failure),
      });
    } finally {
      await cleanupCreateApiDomain();
    }

    if (response != null) {
      // Show the API Secret
      await fetchApiDomains();
      setApiSecretShown(response.data);
    }
  };

  const api_domains = [
    { title: "Name", dataIndex: "api_domain", key: "api_domain" },
    { title: "Creator", dataIndex: "email", key: "email" },
    {
      key: "loading",
      width: 50,
      render: (_, record) => (
        <>{createSecretRowId === record.api_domain && <Spin size="small" />}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <ConfirmationButton
            title="Create a new API Secret?"
            description={
              <>
                <p>
                  <b>
                    Creating a new API secret will invalidate the current API
                    Secret for this domain.
                  </b>
                </p>
                <p>
                  Already issued access tokens will remain valid until they
                  expire.
                </p>
                <p>Do you want to proceed?</p>
              </>
            }
            onConfirm={() => handleCreateApiSecret(record.api_domain)}
            disabled={createSecretRowId != null}
          >
            New API Secret
          </ConfirmationButton>
        </Space>
      ),
    },
    {
      title: "Action",
      key: "delete_action",
      render: (_, record) => (
        <Space>
          <ConfirmationButton
            title="Delete this API Domain?"
            description={
              <>
                <p>
                  <b>
                    Deleting this API Domain will invalidate its API Secret and
                    all its currently outstanding access tokens.
                  </b>
                </p>
                <p>Do you want to proceed?</p>
              </>
            }
            onConfirm={() => handleDeleteApiDomain(record.api_domain)}
            disabled={!record.delete_allowed}
          >
            Delete
          </ConfirmationButton>
        </Space>
      ),
    },
  ];

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "User Id", dataIndex: "user_id", key: "user_id" },
    { title: "Created At", dataIndex: "create_date", key: "create_date" },
    { title: "Expire At", dataIndex: "expire_date", key: "expire_date" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button onClick={() => handleShowToken(record.id)}>Show</Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div>
        <h2>API Domains</h2>
        <div style={{ marginBottom: 10 }}>
          An API Domain is associated with an API Secret. The secret can be used
          to obtain access tokens for the Synthpop public API.
        </div>
        <div>
          <Table
            dataSource={apiDomains}
            columns={api_domains}
            rowKey="api_domain"
          />
        </div>

        <Button
          type="primary"
          onClick={() => setCreateApiVisible(true)}
          style={{ marginBottom: 24, marginTop: 0 }}
        >
          Create API Domain
        </Button>

        <Modal
          title="Create API Domain"
          open={createApiVisible}
          onOk={() => handleCreateApiDomain()}
          onCancel={() => cleanupCreateApiDomain()}
          okButtonProps={{ disabled: newApiDomain.trim() === "" }} // Disable "OK" if no name.
        >
          <p>Please enter the API domain name you want to create:</p>
          <Input
            placeholder="Enter API domain name"
            value={newApiDomain}
            onChange={(e) => setNewApiDomain(e.target.value)}
          />
        </Modal>
      </div>
      <div>
        <h2> Legacy API Keys</h2>
        <Table dataSource={apiKeys} columns={columns} rowKey="id" />
        <Modal
          title=""
          open={isModalVisible}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          {selectedToken && (
            <>
              <CopyIconToClipboard
                key={selectedToken.key}
                title="JWT Token"
                fieldType="text"
                details={selectedToken.token}
                isEditable={false}
              />
            </>
          )}
        </Modal>
      </div>

      <Modal
        title=""
        open={apiSecretShown}
        onOk={() => setApiSecretShown(false)}
        onCancel={() => setApiSecretShown(false)}
        footer={null}
      >
        <>
          {apiSecretShown && (
            <ApiSecretDialog
              title="New API Secret"
              secret={apiSecretShown.secret}
              api_domain={apiSecretShown.api_domain}
            />
          )}
        </>
      </Modal>
    </div>
  );
};

export default ApiKeysTable;
