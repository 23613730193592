import { useContext } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
import "./MdeStyling.css";
import { Button, notification, Typography } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import "./CopyIconToClipboard.css"; // Import the CSS file with styling
import { formatMessage } from "../utils/utilities";
import { useTheme } from "../context/ThemeContext";
import ConfigContext from "../context/ConfigContext";

function ApiSecretDialog(props) {
  const config = useContext(ConfigContext);

  const { isDarkMode } = useTheme();
  const { Title } = Typography;
  // eslint-disable-next-line react/prop-types

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    //el.value = details;
    el.value = props.secret;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    notification.success({ message: formatMessage(config.messages.copy.copy) });
  };

  const generatedLetterBackgroundStyle = {
    backgroundColor: isDarkMode ? "#1f1f1f" : "#e3e3e3",
  };

  return (
    <div className="generated-letter" style={generatedLetterBackgroundStyle}>
      <Title level={3}>{props.title}</Title>

      <p>
        <b>
          Synthpop does not store the secret. Make sure to copy it to a safe
          place before closing this window.
        </b>
      </p>

      <div>
        <p>
          <b>API Domain</b>: {props.api_domain}
        </p>
        <p>
          <b>API Secret</b>:
          <Button
            icon={<CopyOutlined />}
            onClick={copyToClipboard}
            title="Copy to clipbord"
            style={{ marginLeft: "8px" }}
          ></Button>
        </p>

        <pre style={{ marginTop: "10px" }}>{props.secret}</pre>
      </div>
    </div>
  );
}

export default ApiSecretDialog;
