import { useState, useContext } from "react";
import ReactJson from "react-json-view";
import { Button, Switch, Typography, Input } from "antd";
import { CopyOutlined, SaveOutlined, SearchOutlined } from "@ant-design/icons";
import ConfigContext from "../context/ConfigContext";
import { useTheme } from "../context/ThemeContext";
import { notification } from "antd";
import "./InteractiveJsonViewer.css";

function InteractiveJsonViewer(props) {
  const { isDarkMode } = useTheme();
  const { Title } = Typography;
  const [isInteractive, setIsInteractive] = useState(false); // State to toggle views
  const [details] = useState(props.details);
  const [searchQuery, setSearchQuery] = useState(""); // State to track the search query
  const [filteredJson, setFilteredJson] = useState(null); // State to hold filtered JSON content
  useContext(ConfigContext);

  let parsedDetails;
  let isJsonValid = true;

  // Themes for the JSON viewer
  // You can refer this link for more themes:
  // https://mac-s-g.github.io/react-json-view/demo/dist/
  const lightTheme = "rjv-default";
  const darkTheme = "bright";

  try {
    parsedDetails = JSON.parse(details); // Try to parse the JSON
  } catch (e) {
    isJsonValid = false; // Mark as invalid JSON if an error occurs
  }

  // Function to copy JSON to clipboard
  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = details;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    notification.success({ message: "Copied to clipboard!" });
  };

  // Toggle between interactive and simple views
  const toggleView = (checked) => {
    setIsInteractive(checked);
  };

  // Function to filter the JSON content based on search query
  const filterJson = (data, query) => {
    if (!query) return data; // If no search query, return original data
    const filteredData = JSON.parse(JSON.stringify(data)); // Deep copy of data
    const recursiveFilter = (obj) => {
      if (typeof obj === "object" && obj !== null) {
        if (Array.isArray(obj)) {
          return obj.filter((item) => recursiveFilter(item));
        } else {
          const filteredObject = {};
          Object.keys(obj).forEach((key) => {
            if (
              key.toLowerCase().includes(query.toLowerCase()) ||
              (typeof obj[key] === "string" &&
                obj[key].toLowerCase().includes(query.toLowerCase()))
            ) {
              filteredObject[key] = obj[key];
            } else if (typeof obj[key] === "object") {
              const filteredChild = recursiveFilter(obj[key]);
              if (filteredChild && Object.keys(filteredChild).length > 0) {
                filteredObject[key] = filteredChild;
              }
            }
          });
          return Object.keys(filteredObject).length > 0 ? filteredObject : null;
        }
      }
      return typeof obj === "string" &&
        obj.toLowerCase().includes(query.toLowerCase())
        ? obj
        : null;
    };
    return recursiveFilter(filteredData);
  };

  // Handle search input change
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (isJsonValid) {
      const filtered = filterJson(parsedDetails, query);
      setFilteredJson(filtered);
    }
  };

  return (
    <div
      className="interactive-json-viewer"
      style={{
        backgroundColor: isDarkMode ? "#1f1f1f" : "#f9f9f9",
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Title level={3}>{props.title}</Title>
        <div>
          <Button
            icon={<CopyOutlined />}
            onClick={copyToClipboard}
            title="Copy to clipboard"
            style={{ marginRight: "10px" }}
          >
            Copy
          </Button>
          <span style={{ marginRight: "10px" }}>JSON Viewer</span>
          <Switch checked={isInteractive} onChange={toggleView} />
        </div>
      </div>

      {/* Show the search bar only when the JSON is valid */}
      {isInteractive && isJsonValid && (
        <Input
          placeholder="Search JSON..."
          prefix={<SearchOutlined />}
          value={searchQuery}
          onChange={handleSearch}
          style={{ marginBottom: "10px" }}
        />
      )}

      {/* If the details are valid JSON and interactive mode is enabled, show ReactJson */}
      {isInteractive && isJsonValid ? (
        <ReactJson
          src={filteredJson || parsedDetails} // Use filtered JSON if search query is applied, else show full JSON
          name={null}
          collapsed={true}
          enableClipboard={false} // Custom clipboard handling
          displayDataTypes={false}
          displayObjectSize={false}
          style={{ fontSize: "14px" }}
          theme={isDarkMode ? darkTheme : lightTheme}
        />
      ) : (
        // If JSON is invalid or if simple mode is toggled, show text
        <pre>{details}</pre>
      )}

      {props.isEditable && (
        <div className="button-container" style={{ marginTop: "10px" }}>
          <Button
            icon={<SaveOutlined />}
            onClick={() => props.handleSave && props.handleSave()}
            title="Save updated changes"
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

export default InteractiveJsonViewer;
