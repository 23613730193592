import { useState, useEffect } from "react";
import { Switch } from "antd";

function AutoLogout() {
  const defaultInterval = 300000;
  const oneHourInterval = 3600000;
  const storedInterval = localStorage.getItem("autoLogoutInterval");
  const isOneHour = storedInterval === oneHourInterval.toString();
  const [autoLogoutEnabled, setAutoLogoutEnabled] = useState(isOneHour);
  const [autoLogoutInterval, setAutoLogoutInterval] = useState(
    localStorage.getItem("autoLogoutInterval") || defaultInterval.toString()
  );

  useEffect(() => {
    localStorage.setItem("autoLogoutInterval", autoLogoutInterval);
    setAutoLogoutEnabled(autoLogoutInterval === oneHourInterval.toString());
  }, [autoLogoutInterval]);

  const toggleAutoLogout = () => {
    const newInterval =
      autoLogoutInterval === oneHourInterval.toString()
        ? defaultInterval.toString()
        : oneHourInterval.toString();
    setAutoLogoutInterval(newInterval);
  };

  return (
    <div className="api-key-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          paddingBottom: "100px",
        }}
      >
        <span>
          Auto-logout is currently set for{" "}
          {autoLogoutEnabled ? "1 hour" : "5 minutes"}.
        </span>
        <span>
          Toggle to switch to {autoLogoutEnabled ? "5 minutes" : "1 hour"}.
        </span>
        <Switch
          checkedChildren="1 hour"
          unCheckedChildren="5 minutes"
          onChange={toggleAutoLogout}
          checked={autoLogoutEnabled}
        />
      </div>
    </div>
  );
}

export default AutoLogout;
